.fontSize-10 {
    font-size: 0.6rem;
}


.w-10 {
    width: 12%;
}

.clientImages img {
    opacity: 50%;
    margin: auto 1rem;
}

.clientImages img:hover {
    opacity: 100%;
    transition: all 0.1s ease-in;
}