* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e6e6e6;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.divider {
  border-bottom: 3px solid #d6d8db;
  border-width: 100%;
  margin-top: 8rem;
  margin-bottom: 2rem;
}

.border-top-2 {
  border-top: 2px solid #d6d8db;
  border-width: 100%;
}

.text-small {
  text-align: start;
  font-weight: normal;
  color: #d6d8db;
}