.back-btn {
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0.75rem;
}


.back-btn:hover {
    background-color: #C2C4C7;
}

.back-btn:active {
    border: 1px solid transparent !important;
}