.heading {
    font-size: 2.2rem;
    line-height: 2.5rem;
    font-weight: bold;
    color: #333c4d;
}

.card {
    border-radius: 17px !important;
}

.tab {
    position: sticky;
    max-width: 100%;
    height: auto;
    display: flex;
    margin: 20px 0 20px 0;
    background-color: transparent;
    align-items: center;
    justify-content: space-between;
    top: 0;
    background-color: #e6e6e6;
    padding-top: 1rem;
    z-index: 999;
}

.link {
    background-color: transparent;
    border-bottom: 2px solid gray;
    color: gray !important;
    align-items: center;
    padding-block: 5px;
    padding: 0 63.5px 10px 63.8px;
    margin: 0 0 10px 0;
    cursor: pointer;
    font-size: large;
    font-weight: 700;
    text-decoration: none;
}

.link:hover {
    color: black !important;
}

.link:focus {
    color: black !important;
    border-bottom: 2px solid black !important;
}

.active {
    color: black !important;
    border-bottom: 2px solid black !important;
}

.cardtext {
    color: black;
    cursor: pointer;
}

.cardtext:hover {
    color: gray;
    transition-duration: 0.2s;
    opacity: 0.6;
}

.count {
    color: gray;
    opacity: 0.6;
}

.usertext {
    font-size: small;
    color: gray;
    opacity: 0.7;
}



.pbtn {
    background-color: #ea5234;
    color: white;
    border: none;
    border-radius: 17px !important;
    padding: 0.75rem;
    font-weight: 600;
    margin-bottom: 15px;
}

.pbtn:hover {
    background-color: #D03516;
    color: white;
}

.btn__primary {
    background-color: #ea5234;
    color: white;
    border: none;
    padding: 0.75rem;
    font-weight: 600;
    margin-bottom: 15px;
}

.btn__primary:hover {
    background-color: #D03516;
    color: white;
}


.fontWeight-700 {
    font-weight: 700 !important;
}

/* card css styling  */

.likes {
    text-decoration: none;
    cursor: pointer;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    filter: saturate(50%);
}

.likes:hover {
    background-color: gray;
    transition-duration: 0.2s;
    filter: saturate(100%);
}

.dislike {
    text-decoration: none;
    cursor: pointer;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    filter: saturate(50%);
}

.dislike:hover {
    background-color: gray;
    transition-duration: 0.2s;
    filter: saturate(100%);
}

.user-icon {
    color: gray;
    cursor: pointer;
    text-decoration: none;
}

.user-icon:hover {
    color: #f2c4bc;

}

.username:hover {
    border-bottom: 1px solid #f2c4bc;
}

.bg__sponsor:hover {
    background-color: #C2C4C7;
}

.lightColor {
    color: #333c4d;
    opacity: 0.6;
    font-size: 0.75rem;
}

.darkColor {
    color: #333f54;
}