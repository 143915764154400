.head-container {
    width: 100%;
    background-color: white;
    height: 4rem;
    margin-top: 2rem;
    border-radius: 20px;
}

.head-container .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    height: 4rem;
    padding: 0 10px;
}

.head-container .hack-btn {
    background-color: #EA5234;
    border: none;
    padding: 0.12rem 0.5rem;
    display: flex;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    transition: all 5ms ease-in;
}

.head-container .hack-btn:hover {
    background-color: #D03516;
}

.head-container .ml-1 {
    margin-left: 3px;
    text-align: left;
}

.head-container.btn-text {
    font-weight: 600;
    text-transform: uppercase;
}

.head-container .nav-center {
    position: relative;
    padding: 2px 18px;
}

.head-container .nav-center:hover {
    background-color: #D6D8DB;
    border-radius: 10px;
}

.head-container .nav-center:hover .left {
    font-size: 16px;
    transition: all 0.1s ease-in;
}

.head-container .nav-center:hover .right {
    font-size: 20px;
    transition: all 0.1s ease-in;
}

.head-container .nav-center .left {
    position: absolute;
    left: -5px;
    top: -5px;
    transform: rotate(-10deg);
    font-size: 20px;
}

.head-container .logo-text {
    font-size: 26px;
    font-weight: bold;
    color: #333F54;
}

/* 
.head-container .nav-center .logo:hover {
    background-color: #D6D8DB;
} */

.head-container .nav-center .right {
    position: absolute;
    right: -5px;
    bottom: 0rem;
    transform: rotate(10deg);
    font-size: 16px;
}

.head-container .user-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.head-container .avatar {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 3px;
    overflow: hidden;
    object-fit: contain;
}

.head-container .avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.head-container .nav-end a {
    color: black;
    text-decoration: none;
    padding: 4px;
    border-radius: 8px;
}

.head-container .nav-end a:hover {
    background-color: #D6D8DB;
}

.head-container .text-uppercase {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.head-container .text-orange {
    color: #EA5234;
}

.text__underline {
    border-bottom: 3px solid #EA5234;
}