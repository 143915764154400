.backbtn {
    margin: 10px 0 10px 0;
    padding: 0 10px 0 10px;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition-duration: .2s;
}

.heading {
    margin-bottom: 16%;
}

.desc {
    display: inline;
    align-items: center;
    justify-content: center;
    margin-bottom: 10% !important;
}

.desc p {
    font-weight: 600;
    display: inline;
}

.timeline {
    font-weight: 600;
}

.timeline p:first-child {
    margin-top: 10%;
}

.span1 {
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0;
    margin: 0;
    padding: 0;
    position: relative;
    max-width: 100%;
}

.span2 {
    box-sizing: border-box;
    display: block;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0;
    margin: 0;
    padding: 0;
    max-width: 100%;
}

.adimg {
    display: block;
    max-width: 100%;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0;
    border-radius: 15px;
    margin: 0;
    padding: 0;
}

.your {
    margin: 5% 0 2% 0;
    font-weight: 600;
}

.list {
    list-style-type: disc;
    list-style: inside;
    margin: 0 0 10% 0;
}

.get {
    margin: 0 0 2% 0;
    font-weight: bold;
    font-size: x-large;
}

.bluetext {
    color: #3eaeef;
    font-weight: 600;
}



.steps {
    list-style: steps;
    /* background: #CFCFCF; */

}

.h-32 {
    height: 8rem;
}

.select {
    border: 1;
}

.adtext {
    display: inline !important;
    justify-content: space-evenly;
}

/* .faqb{
    align-items: center;
    width: 100%;
    max-height: 20%;
    font-weight: 600;
    position: left;
    padding: 3% 0 3% 0;
    border-top: 3%;
} */

.Collapsible {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 500;
    padding: 2% 0 2% 0;
    border-top: 0.5px solid lightgray;
}

.Collapsible p {
    color: gray;
    margin-top: 2%;
    font-size: smaller;
    font-weight: 400;
    margin-left: 6%;
}

.money {
    text-decoration: line-through;
}

.avatar {
    min-height: 100%;
}

.avatardiv {
    box-sizing: border-box;
    display: inline-flex;
    overflow: hidden;
    width: initial;
    height: initial;
    border: 0px;
    margin: 0px;
    padding: 0px;
    position: absolute;
    inset: 0px;
}

.avatarpic {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: inline-flex;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.tlink {
    color: black;
}

.tlink:hover {
    color: rgba(255, 0, 0, 0.555);
}