.container {
  max-width: 36rem;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.footer_bottom {
  background: #e6e6e6;
}

.cross_icon {
  position: absolute;
  right: 1rem;
  cursor: pointer;
}

.font-size-14 {
  font-size: 14px;
}

/* @media (max-width:767px) {
  .container {
    max-width: 100%;
  }
} */